import React from 'react';
import styled from "styled-components";
import CursorHand from "../../images/cursor-hand.gif";

const RichText = ({data}) => {
  return (
    <RichContainer>
      <RichImage src={data.image.localFile.url} alt='conditions'/>
      <RichContent
        dangerouslySetInnerHTML={{
          __html: data.content.data.content,
        }}
      />
    </RichContainer>
  )
};

export default RichText

const RichContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16rem 12.5rem 12rem;
  justify-content: end;
  align-items: flex-start;
  word-break: break-word;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 95px 18px 20px;
    display: block;
  }
`

const RichImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 56rem;
  max-width: 48rem;
  margin-right: 16rem;

  @media screen and (max-width: 768px) {
    margin: 0;
    max-width: 31%;
    float: right;
  }
`

const RichContent = styled.div`
  width: 55%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  h1{
    font-family: 'SF Pro Display Heavy Italic', sans-serif;
    font-style: italic;
    font-weight: 800;
    color: #1AEFBC;
    font-size: 3rem;
    line-height: 103.19%;
    margin: 4.7rem 0 2.3rem;

    &:first-child{
      font-size: 5.2rem;
      margin: 1rem 0 1.8rem;

      + p {
        margin-bottom: 4.6rem;
        opacity: 0.7;
      }
    }

    @media screen and (max-width: 1200px) {
      font-size: 30px;

      &:first-child{
        font-size: 52px;
        margin: 20px 0 18px;

        + p {
          margin-bottom: 46px;
        }
      }
    }
  }

  h2{
    font-family: 'SF Pro Display Heavy Italic', sans-serif;
    font-style: italic;
    font-weight: 600;
    color: #1AEFBC;
    font-size: 2rem;
    line-height: 103.19%;
    margin-bottom: 2.3rem;

    @media screen and (max-width: 1200px) {
      font-size: 20px;
      margin-bottom: 23px;
    }
  }

  h3{
    font-family: 'SF Pro Display Semibold Italic', sans-serif;
    font-style: italic;
    font-weight: 600;
    color: #1AEFBC;
    font-size: 1.6rem;
    line-height: 131.19%;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @media screen and (max-width: 1200px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  p{
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 132.2%;
    color: #FFFFFF;
    margin-bottom: 2.2rem;

    @media screen and (max-width: 1200px) {
      font-size: 16px;
      margin-bottom: 22px;
    }

    strong {
      color: #1AEFBC;
    }
  }

  ul{
    list-style: none;
    margin-bottom: 30px;
    li {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 132.2%;
      color: #FFFFFF;
      position: relative;
      padding-left: 1.8rem;

      &:before{
        background-color: #1AEFBC;
        border-radius: 50%;
        content: "";
        height: 0.8rem;
        width: 0.8rem;
        position: absolute;
        left: 0;
        top: 0.5rem;
      }

      strong {
        color: #1AEFBC;
      }

      @media screen and (max-width: 1200px) {
        font-size: 16px;
        padding-left: 18px;

        &:before{
          height: 8px;
          width: 8px;
          top: 7px;
        }
      }
    }
  }

  a {
    color: #1AEFBC;
    cursor: url(${CursorHand}), auto;
  }

  #gdpr {
    scroll-margin-top: 11rem;

    @media screen and (max-width: 768px) {
      scroll-margin-top: 80px;
    }
  }
`

