import React from 'react';
import styled from "styled-components";
import BenefitCard from "../elements/benefit-card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {motion} from "framer-motion";
import {fromBottom, fromRight} from "../../utils/animationVariants";

const Benefits = ({data}) => {

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    centerMode: true,
    variableWidth: true,
    infinite: true,
    focusOnSelect: true,
    cssEase: 'linear',
    touchMove: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <BenefitsContainer id='benefits'>
      <BenefitsContent>
        <BenefitsTitle
          as={motion.div}
          initial={"offscreen"}
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromRight}
        >{data.title}</BenefitsTitle>

        <motion.div
          initial={"offscreen"}
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromBottom}
        >
          <BenefitsCards {...settings}>
            {data.cards.map((card, i) => (
              <BenefitCard card={card} key={i}/>
            ))}
          </BenefitsCards>
        </motion.div>

      </BenefitsContent>
    </BenefitsContainer>
  )
};

export default Benefits

const BenefitsContainer = styled.div`
  min-height: 56vw;
  display: flex;
  align-items: center;
  padding: 0 12rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    min-height: auto;
    padding: 50px 0 70px;
  }
`

const BenefitsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  //padding-top: 9rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding-top: 57px;
  }
`
const BenefitsTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  color: #1AEFBC;
  font-style: italic;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 103.19%;
  margin-bottom: 9.4rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 40px;
  }
`

const BenefitsCards = styled(Slider)`
  @media screen and (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 2.2rem;
    grid-row-gap: 2.2rem;
    //max-width: 138.5rem;
    max-width: 144.5rem;
    margin: 0 auto;
  }

  .slick-list {
    padding: 90px 0 !important;

    .slick-slide {
      position: relative;
      z-index: 1;

      transform: scale(1.3);
      transition: transform 400ms;
      transition-timing-function: linear;

      &.slick-current{
        z-index: 10;
        transform: scale(1.3) translate(0, -35px);
        transition: transform 400ms;
        transition-timing-function: linear;
        //margin-top: -50px;
      }
    }
  }
`
