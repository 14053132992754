import React from 'react';
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CursorHand from "../../images/cursor-hand.gif"
import {motion} from "framer-motion";
import {fromLeft, fromRight} from "../../utils/animationVariants";

const FAQ = ({data}) => {
  return (
    <FAQContainer id='faq'>
      <FAQTitle
        as={motion.div}
        initial={"offscreen"}
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={fromLeft}
      >{data.title}</FAQTitle>

      <FAQAccordionWrap
        as={motion.div}
        initial={"offscreen"}
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={fromRight}
      >
        <FAQAccordion preExpanded={[0]}>
          {data.questions.map((item, i) => (
            <FAQAccordionItem key={i} uuid={i}>
              <AccordionItemHeading>
                <FAQAccordionItemButton>
                  {item.question}
                </FAQAccordionItemButton>
              </AccordionItemHeading>
              <FAQAccordionItemPanel>
                {item.answer}
              </FAQAccordionItemPanel>
            </FAQAccordionItem>
          ))}
        </FAQAccordion>
      </FAQAccordionWrap>
    </FAQContainer>
  )
};

export default FAQ

const FAQContainer = styled.div`
  min-height: 56vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding: 32.3rem 26rem 1rem 33rem;
  background: #201860;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
    padding: 90px 18px 70px;
  }
`

const FAQTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  color: #1AEFBC;
  font-style: italic;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 103.19%;

  @media screen and (max-width: 768px) {
    font-weight: 800;
    font-size: 26px;
    line-height: 103.19%;
    margin: 0 auto;
  }
`

const FAQAccordionWrap = styled.div`
  width: 60%;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 63px;
  }
`
const FAQAccordion = styled(Accordion)``
const FAQAccordionItem = styled(AccordionItem)`
  margin-bottom: 0.8rem;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-bottom: 3.4px;
  }
`

const FAQAccordionItemButton = styled(AccordionItemButton)`
  font-family: 'SF Pro Display Medium', sans-serif;
  padding: 2rem 3.3rem 1.5rem 4.4rem;
  width: 100%;
  text-align: left;
  color: #1AEFBC;
  cursor: url(${CursorHand}), auto;
  font-style: normal;
  font-weight: 500;
  font-size: 2.3rem;
  line-height: 103.19%;
  background: #464377;
  border: 1px solid #7B77B9;
  border-radius: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    display: inline-block;
    content: '';
    height: 0.9rem;
    width: 0.9rem;
    border-bottom: 0.2rem solid currentColor;
    border-right: 0.2rem solid currentColor;
    transform: rotate(-45deg);
    margin-left: 10px;
  }

  &[aria-expanded='true'] {
    background: #1AEFBC;
    color: #312E62;
  }
  &[aria-expanded='true']::after, &[aria-selected='true']::after{
    transform: rotate(45deg);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 28px 10px 16px;
    min-height: 60px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140.2%;
    border: 0.8px solid #7B77B9;
    border-radius: 9px;

    &::after {
      height: 9px;
      width: 9px;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      transform: rotate(-45deg);
    }
  }
`

const FAQAccordionItemPanel = styled(AccordionItemPanel)`
  font-family: 'SF Pro Display Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 145%;
  color: #FFFFFF;
  padding: 2rem 7rem 2rem 4.4rem;


  @media screen and (max-width: 768px) {
    padding: 10px 30px 10px 16px;
    font-size: 12px;
  }
`


