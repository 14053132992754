import React from 'react';
import styled from "styled-components";

const BenefitCard = ({card}) => {
  return (
    <CardContainer>
      <CardBG></CardBG>
      <Card>
        <CardImage src={card.image.localFile.url} alt={card.title} width='50' height='50'/>
        <CardTitle
          dangerouslySetInnerHTML={{
            __html: card.title,
          }}
        />
        <CardDescription
          dangerouslySetInnerHTML={{
            __html: card.description,
          }}
        />
      </Card>
    </CardContainer>
  )
};

export default BenefitCard;


const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 769px) {
    &:hover{
      transform: translateY(-1rem);
      transition: ease 250ms;
    }
  }
`
const CardBG = styled.div`
  width: auto;
  height: auto;
  background: #6558B6;
  border-radius: 2.3rem;
  margin-bottom: 1.3rem;
  margin-right: 1.8rem;
  grid-row-start: 1;
  grid-column-start: 1;

  @media screen and (max-width: 768px) {
    width: 157px;
    height: 250px;
    border-radius: 12px;
    margin-bottom: 7px;
    margin-right: 11px;
  }
`

const Card = styled.div`
  background: #A093ED;
  border: 0.1rem solid #6C63B9;
  border-radius: 2.3rem;
  width: auto;
  height: auto;
  margin-top: 1.3rem;
  margin-left: 1.7rem;
  grid-row-start: 1;
  grid-column-start: 1;
  text-align: right;
  padding: 5rem 3rem 7.3rem 5rem;

  @media screen and (max-width: 768px) {
    width: 157px;
    height: 260px;
    border-radius: 12px;
    margin-top: 7px;
    margin-left: 8px;
    padding: 27px 15px 24px 30px;
  }
`
const CardImage = styled.img`
  height: 6rem;
  width: auto;
  margin-left: auto;

  @media screen and (max-width: 768px) {
    height: 28px;
  }
`
const CardTitle = styled.div`
  font-family: 'SF Pro Display Semibold Italic', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 135.2%;
  margin: 1rem 0 2rem;
  text-align: left;
  min-height: 14rem;
  color: #100D40;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 5px 0;
    min-height: 80px;
  }
`
const CardDescription = styled.div`
  font-family: 'SF Pro Display Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 145.2%;
  text-align: left;
  color: #312E62;
  width: auto;

  @media screen and (max-width: 768px) {
    font-size: 12px;

    br {
      display: none;
    }
  }
`
