import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Sections from "../components/sections";
import Seo from "../components/seo";


const DynamicPage = ({data, pageContext}) => {
  const {contentSections, localizations} = data.strapiPage
  const global = data.strapiGlobal

  return (
    <>
      <Seo global={global} />
      <Layout global={global} pageContext={{...pageContext, localizations}}>
        <Sections sections={contentSections}/>
      </Layout>
    </>
  )
}


export default DynamicPage

export const query = graphql`
  fragment GlobalData on STRAPI_GLOBAL {
    id,
    metadata {
      metaTitle
      metaDescription
      metaKeywords
      twitterCardType
      twitterUsername
      shareImage {
        localFile {
          url
        }
      }
    }
    favicon {
      localFile {
        url
      }
    }
    navbar {
      links {
        url
        newTab
        text
      }
      logo {
        localFile {
          url
        }
      }
    }
    footer {
      copyright
      title
      subTitle
      links {
        newTab
        text
        url
      }
      socials {
        newTab
        url
        image {
          localFile {
            url
          }
        }
      }
    }
  }

  query DynamicPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiPage(id: { eq: $id }) {
      slug
      shortName
      localizations {
        data {
          attributes {
            locale
          }
          id
        }
      }
      contentSections {
        ... on STRAPI__COMPONENT_SECTIONS_HERO {
          id
          strapi_component
          steps {
            title
          }
          title
        }
        ... on STRAPI__COMPONENT_SECTIONS_RICH_TEXT {
          id
          strapi_component
        }
        ... on STRAPI__COMPONENT_SECTIONS_TO_REACH_THE_GOAL {
          id
          strapi_component
          title
          image {
            localFile {
              url
            }
          }
          steps {
            title
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_START_THE_WAY {
          id
          strapi_component
          title
          steps {
            title
            description
            image {
              localFile {
                url
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_BENEFITS {
          id
          strapi_component
          title
          cards {
            title
            description
            image {
              localFile {
                url
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_ROAD_MAP {
          id
          strapi_component
          title
          months {
            title
            steps {
              title
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEAM {
          id
          strapi_component
          title
          members {
            firstName
            lastName
            position
            photo {
              localFile {
                url
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_FAQ {
          id
          strapi_component
          title
          questions {
            question
            answer
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_JOIN {
          id
          strapi_component
          title
          subTitle
          image {
            localFile {
              url
            }
          }
          giftText {
            data {
              giftText
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_RICH_TEXT {
          id
          content {
            data {
              content
            }
          }
          image {
            localFile {
              url
            }
          }
        }

      }
    }
  }
`
