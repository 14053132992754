import React, {useRef} from 'react';
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import {motion, useInView} from "framer-motion";
import MobileImage from "../images/phone-animations/diagram.svg"
import GoalBackground from "../images/phone-animations/goal-card-background.svg"

const variants = (delay, duration = 3) => ({
  initial: { opacity: 0, pathLength: 0 },
  animation: {
    opacity: [0, 1],
    scale: [null, 1.3, 1],
    transition: { duration: 0.5, delay: delay}
  },
  afterAnimation: {
    translateY: [0, 15, 0],
    transition: {
      type: "spring",
      duration: duration,
      repeat: Infinity,
      ease: "easeInOut",
    }
  }
});

const Mobile = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <MobileContainer>
      <StaticImage
        src='../images/phone-animations/phone-background.png'
        alt='background-mobile'
        width={303}
        height={611}
        quality={100}
      />
      <GoalCard
        as={motion.div}
        animate={isInView ? {
          translate: "20% 5%",
          scale: [1, 1.2],
          boxShadow: '-11px 13px 9px rgba(0,0,0, 0.53)',
        } : {boxShadow: 'none'}}
        viewport={{ once: true }}
        transition={{
          duration: 0.8,
          ease: "easeInOut",
        }}
      >
        <GoalCardContent>
          <GoalCardContentTitle>
            No sugar for a month
            except morning coffee
          </GoalCardContentTitle>
          <GoalCardDescription>
            <GoalCardBigNumber>
              95
            </GoalCardBigNumber>
            <GoalCardPercentWrap>
              <GoalCardPercent>%</GoalCardPercent>
              <GoalCardSuccess>success</GoalCardSuccess>
            </GoalCardPercentWrap>
          </GoalCardDescription>
        </GoalCardContent>
        <img src={MobileImage} alt='diagram'/>
      </GoalCard>

      <ChargeContainer
        as={motion.div}
        initial={"initial"}
        animate={isInView ? ["animation", "afterAnimation"] : { opacity: 0 }}
        variants={variants(1)}
      >
        <StaticImage
          src='../images/phone-animations/charged.png'
          alt='charged'
          quality={100}
          width={245}
          height={76}
        />
        <StepTitle>Charge</StepTitle>
        <StepSubTitle>
          Energy Booster<br/>
          for your goal
        </StepSubTitle>
      </ChargeContainer>

      <FirstArrow>
        <FirstArrowContent
          as={motion.div}
          initial={"initial"}
          animate={isInView ? ["animation", "afterAnimation"] : { opacity: 0 }}
          variants={variants(2.5, 2)}
        >
          <StepTitle>Reach</StepTitle>
          <StepSubTitle>The Goal</StepSubTitle>
        </FirstArrowContent>
        <svg width="64" height="98" viewBox="0 0 64 98" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path
            d="M35.8471 95.3915C45.48 86.6672 56.8329 67.8442 56.4028 53.8881C55.783 33.7775 29.5745 34.1401 23.3948 43.1391C17.2151 52.138 18.5243 56.0168 27.4905 53.5663C36.4568 51.1158 47.8367 32.0648 35.9914 15.3164C28.6957 5.00084 14.3537 10.6659 11.712 11.9416"
            stroke="#A093ED"
            strokeWidth="2"
            initial={{ opacity: 0, pathLength: 0 }}
            animate={isInView ? { opacity: 1, pathLength: 1 } : { opacity: 0, pathLength: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2, delay: 1.5, type: "spring", ease: "easeInOut" }}
          />
          <motion.path
            d="M16.5115 0.651942L11.7203 12.003L19.0532 20.5678"
            stroke="#A093ED"
            strokeWidth="2"
            initial={{ opacity: 0, pathLength: 0 }}
            animate={isInView ? { opacity: 1, pathLength: 1 } : { opacity: 0, pathLength: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 2.3, type: "spring" }}
          />
        </svg>
      </FirstArrow>
      <SecondArrow>
        <svg width="87" height="84" viewBox="0 0 87 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path
            d="M77.4129 82.8852C57.045 78.4925 16.9195 56.5627 19.3613 3.98413"
            stroke="#A093ED"
            strokeWidth="2"
            initial={{ opacity: 0, pathLength: 0 }}
            animate={isInView ? { opacity: 1, pathLength: 1 } : { opacity: 0, pathLength: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 2, delay: 3, type: "spring", ease: "easeInOut" }}
          />
          <motion.path
            d="M29.7161 8.84164L19.1673 2.87906L9.04311 9.56537"
            stroke="#A093ED"
            strokeWidth="2"
            initial={{ opacity: 0, pathLength: 0 }}
            animate={isInView ? { opacity: 1, pathLength: 1 } : { opacity: 0, pathLength: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 3.5, type: "spring" }}
          />
        </svg>
      </SecondArrow>
      <EarnMoney
        as={motion.div}
        initial={"initial"}
        animate={isInView ? ["animation", "afterAnimation"] : { opacity: 0 }}
        variants={variants(4)}
      >
        <StaticImage
          src='../images/phone-animations/token.png'
          alt='token'
          width={84}
          height={61}
          quality={100}
        />
        <EarnMoneyContent>
          <StepTitle>Earn</StepTitle>
          <StepSubTitle>Real Money</StepSubTitle>
        </EarnMoneyContent>
      </EarnMoney>
      <div ref={ref}></div>
    </MobileContainer>
  );
};

export default Mobile;

const MobileContainer = styled.div`
  position: relative;
  //height: 611px;
  //width: 303px;
  margin: 10px 180px 0 110px;
  width: 30vw;
  height: auto;
  max-width: 303px;

  @media screen and (max-width: 768px) {
    order: 1;
    margin: 0 auto;
    width: 40vw;
    max-width: 303px;
    height: auto;
  }
`
const GoalCard = styled.div`
  position: absolute;
  width: 83%;
  height: 18%;
  left: 8.5%;
  top: 30.5%;
  border-radius: 20.5px;
  overflow: hidden;
  display: flex;
  background: url(${GoalBackground}) no-repeat center center / cover;

  img {
    width: 100px;
    height: auto;

    @media screen and (max-width: 768px) {
      width: 13vw;
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 3.5vw;
  }
`

const GoalCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-top: 8%;
`
const GoalCardContentTitle = styled.div`
  font-family: 'SF Pro Display Regular', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: rgba(16, 13, 64, 1);
  letter-spacing: -0.02em;
  width: 110%;

  @media screen and (max-width: 950px) {
    font-size: 11px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.75vw;
    line-height: 2vw;
  }
`
const GoalCardDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media screen and (max-width: 950px) {
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    margin-top: 1vw;
  }
`
const GoalCardBigNumber = styled.div`
  font-size: 33px;
  line-height: 39px;
  color: rgba(16, 13, 64, 0.5);
  letter-spacing: -0.02em;
  margin-right: 5px;

  @media screen and (max-width: 768px) {
    font-size: 4.6vw;
    line-height: 5.2vw;
    margin-right: 0.5vw;
  }
`
const GoalCardPercentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const GoalCardPercent = styled.div`
  font-family: 'SF Pro Display Bold', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: rgba(16, 13, 64, 0.5);
  letter-spacing: -0.02em;

  @media screen and (max-width: 768px) {
    font-size: 1.75vw;
    line-height: 2vw;
  }
`
const GoalCardSuccess = styled.div`
  font-family: 'SF Pro Display Bold', sans-serif;
  font-size: 10px;
  line-height: 10px;
  color: rgba(16, 13, 64, 0.5);
  letter-spacing: -0.02em;

  @media screen and (max-width: 768px) {
    font-size: 1.2vw;
    line-height: 1vw;
  }
`

const ChargeContainer = styled.div`
  position: absolute;
  text-align: center;
  will-change: transform;
  bottom: 9%;
  right: -63%;

  @media screen and (max-width: 768px) {
    width: 75%;
    bottom: 9%;
    right: -63%;
  }
`

const FirstArrow = styled.div`
  position: absolute;
  top: 34%;
  right: -58%;

  width: 41%;

  svg {
    width: 51%;
    margin: 15% 0 0 25%;
    height: auto;
  }
`

const FirstArrowContent = styled.div`
  text-align: center;
  will-change: transform;
  margin: 0;
`

const SecondArrow = styled.div`
  position: absolute;
  left: -32%;
  top: 27%;
  width: 29%;

  svg {
    width: 100%;
    height: auto;
  }
`

const EarnMoney = styled.div`
  position: absolute;
  background: #1F1C61;
  box-shadow: 7px -1px 9px rgba(0, 0, 0, 0.53);
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
  width: 84%;
  height: 18%;
  top: 6%;
  left: -50%;
  padding-left: 5%;

  @media screen and (max-width: 768px) {
    border-radius: 3.5vw;
  }
`

const EarnMoneyContent = styled.div`
  margin-left: 17px;

  @media screen and (max-width: 768px) {
    margin-left: 2vw;
  }
`

const StepTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic',sans-serif;
  font-style: italic;
  font-weight: 800;
  font-size: 26px;
  line-height: 127.69%;
  text-transform: capitalize;
  color: #1AEFBC;

  @media screen and (max-width: 768px) {
    font-size: 3.6vw;
  }
`

const StepSubTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic',sans-serif;
  font-style: italic;
  font-weight: 800;
  font-size: 20px;
  line-height: 127.69%;
  color: #1AEFBC;

  @media screen and (max-width: 768px) {
    font-size: 2.7vw;
  }
`


