import React from 'react';
import styled from "styled-components";
import CloseIcon from "../../images/close.svg"
import CursorHand from "../../images/cursor-hand.gif"

const Modal = ({setShow, children}) => {
  return(
    <>
      <DarkBg onClick={() => setShow(false)}></DarkBg>
      <ModalWrap>
        <ModalContainer>
          <ModalClose onClick={() => setShow(false)} />
          {children}
        </ModalContainer>
      </ModalWrap>
    </>
  );
};

export default Modal;

const DarkBg = styled.div`
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  background: #060C3A;
  opacity: 0.66;
  filter: blur(13px);
  z-index: 10;
`
const ModalWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`
const ModalContainer = styled.div`
  //width: 52vw;
  //height: 61vh;
  min-width: 100rem;
  width: auto;
  height: auto;
  min-height: 61rem;
  background: #2C2568;
  border: 0.3rem solid #A093ED;
  border-radius: 2.4rem;
  color: white;
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  align-items: center;
  overflow-y: auto;

  @media screen and (max-width: 1300px) {
    max-height: 90vh;
    min-height: 444px;
    min-width: 733px;
    max-width: 90vw;
    gap: 42px;
    border-radius: 17px;
    border: 2px solid #A093ED;
  }


  @media screen and (max-width: 767px) {
    width: 90vw;
    max-height: 90vh;
    height: 90vh;
    min-width: auto;
    display: block;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    gap: 24px;
    padding-bottom: 20px;
  }
`

const ModalClose = styled.div`
  background: url(${CloseIcon}) no-repeat;
  background-size: contain;
  cursor: url(${CursorHand}), auto;
  position: absolute;
  top: 4rem;
  right: 4rem;
  width: 1.7rem;
  height: 1.7rem;

  @media screen and (max-width: 1300px) {
    top: 29px;
    right: 29px;
    width: 12px;
    height: 12px;
  }

  @media screen and (max-width: 767px) {
    top: 28px;
    right: 28px;
    width: 19px;
    height: 19px;
  }
`
