import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import LeadForm from "../lead-form";
import {useStaticQuery, graphql} from "gatsby";
import PopupLaunch from "../modals/PopupLaunch";
import GiftBG from "../../images/gift-normal.svg"
import {LaunchContext} from "../context";
import {motion} from "framer-motion";
import {fromLeft, fromTop, fromRight} from "../../utils/animationVariants";

const Join = ({data}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { showLaunchModal } = useContext(LaunchContext)
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    const listener = () => {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;
      const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;

      if (showLaunchModal && bottomOfWindow && !triggered) {
        setTimeout(() => {
          setIsOpen(true);
        }, 4000);
        setTriggered(true);
      }
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [triggered, showLaunchModal]);

  const logo = useStaticQuery(graphql`
   query {
    strapiGlobal {
      navbar {
        logo {
          localFile {
            url
          }
        }
      }
    }
   }
  `);
  return (
    <JoinContainer id='to-reach-the-goal'>
      <JoinContent>
        <Logo
          src={logo.strapiGlobal.navbar.logo.localFile.url}
          alt='Goal Booster Logo'
          width='100'
          height='50'
          as={motion.div}
          initial={"offscreen"}
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromTop}
        />
        <motion.div
          as={motion.div}
          initial={"offscreen"}
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromLeft}
        >
          <JoinTitle>{data.title}</JoinTitle>
          <JoinSubTitle>{data.subTitle}</JoinSubTitle>
        </motion.div>
        <LeadFormDesktopWrap>
          <LeadForm/>
        </LeadFormDesktopWrap>
      </JoinContent>
      <GiftWrap
        dangerouslySetInnerHTML={{
          __html: data.giftText.data.giftText,
        }}
        as={motion.div}
        initial={"offscreen"}
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={fromRight}
      />
      <LeadFormMobileWrap>
        <LeadForm/>
      </LeadFormMobileWrap>
      {isOpen && <PopupLaunch setIsOpen={setIsOpen} data={data}/>}
    </JoinContainer>
  )
};

export default Join

const JoinContainer = styled.div`
  min-height: 56vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10% 12rem;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    min-height: auto;
    padding: 90px 0 60px;
  }
`

const Logo = styled.img`
  width: 20rem;
  height: auto;
  margin-bottom: 11rem;

  @media screen and (max-width: 768px) {
    width: 150px;
    height: 58px;
    margin-bottom: 50px;
  }
`

const JoinContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 45rem;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0 18px;
  }
`
const JoinTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  color: #1AEFBC;
  font-style: italic;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 103.19%;
  margin-bottom: 2rem;
  max-width: 35rem;

  @media screen and (max-width: 768px) {
    font-weight: 800;
    font-size: 41px;
    line-height: 103.19%;
    margin-bottom: 10px;
  }
`
const JoinSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 122.69%;
  color: #FFFFFF;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 122.69%;
    margin-bottom: 30px;
  }
`

const GiftWrap = styled.div`
  width: 75rem;
  margin-top: 13%;
  background: url(${GiftBG}) no-repeat center center;
  background-size: contain;
  height: 45rem;
  color: #fff;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 122.69%;
  text-align: center;
  padding: 27rem 13rem 0 0;

  p{
    &:first-child {
      font-family: 'SF Pro Display Heavy Italic', sans-serif;
      span {
        font-family: 'SF Pro Display Heavy Italic', sans-serif;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
    width: 100%;
    font-size: 14px;
    padding: 155px 75px 0 0;
    height: 255px;
  }
`

const LeadFormDesktopWrap = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const LeadFormMobileWrap = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 60px 18px 0;
  }
`
