import React from 'react';
import styled, {css} from "styled-components";
import RouteImage from '../../images/route.inline.svg'
import ManImage from '../../images/man.inline.svg'
import HeroStepImage from "../../images/hero-step.svg"
import HeroStepImageFilled from "../../images/hero-step-filled.svg"
import LeadForm from "../lead-form"
import { StaticImage } from 'gatsby-plugin-image';

const Hero = ({data}) => {
  return (
    <HeroContainer>
      <HeroBG>
        <StaticImage
          src='../../images/mountain.png'
          alt='mountain'
          layout="fullWidth"
          quality={100}
          loading="eager"
        />
      </HeroBG>
      <HeroMobileBG>
        <StaticImage
          src='../../images/mountain-mobile.png'
          alt='mountain'
          layout="fullWidth"
          quality={100}
          loading="eager"
        />
      </HeroMobileBG>
      <HeroRouteImage/>
      <HeroManImage/>
      <HeroContent>
        <HeroTitle>{data.title}</HeroTitle>
        <HeroSteps>
          {data.steps.map((step, i) => (
            <HeroStep key={i}>
              <HeroStepNumber>{(i + 1).toString().padStart(2, "0")}</HeroStepNumber>
              <HeroStepTitle>{step.title}</HeroStepTitle>
            </HeroStep>
          ))}
        </HeroSteps>
        <LeadForm/>
      </HeroContent>
    </HeroContainer>
  )
};

export default Hero


function createAnimationCSS() {
  let styles = '';

  for (let i = 0; i < 11; i += 1) {
    styles += `
      .st${i+2} {
        animation: step12 576ms ease;
        animation-fill-mode: forwards;
        animation-delay: ${6 - 0.5*i}s;

        @keyframes step${i+2} {
          0% {
            fill: #A093ED;
            r: 7.5;
          }
          50% {
            r: 15;
          }
          100% {
            r: 7.5;
            fill: #1AEFBC;
          }
        }
      }
     `
  }
  return css`${styles}`;
}


const HeroContainer = styled.div`
  min-height: 56vw;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    min-height: auto;
    overflow: hidden;
    position: relative;
  }
`
const HeroBG = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;

  @media screen and (max-width: 768px) {
    top: 2.4rem;
  }
  @media screen and (max-width: 460px) {
    display: none;
  }
`

const HeroMobileBG = styled.div`
  display: none;
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  top: 0;

  @media screen and (max-width: 460px) {
    display: block;
  }
`

const HeroRouteImage = styled(RouteImage)`
  height: 50rem;
  width: 60rem;
  position: absolute;
  top: 31.4rem;
  left: 102rem;
  overflow: visible;

  @media screen and (max-width: 768px) {
    height: 12.5rem;
    width: 15rem;
    position: absolute;
    top: 9.4rem;
    left: 25.7rem;
  }

  @media screen and (max-width: 460px) {
    //display: none;
    height: 40rem;
    width: 35rem;
    position: absolute;
    top: 11.2rem;
    left: 18rem;
  }

  .st0{
    animation: step0 576ms ease;
    animation-fill-mode: forwards;
    animation-delay: 2s;

    @keyframes step0 {
      from {
        fill: #A093ED;
      }
      to {
        fill: #1AEFBC;
      }
    }
  }

  .st1 {
    animation: step1 576ms ease;
    animation-fill-mode: forwards;
    animation-delay: 4s;

    @keyframes step1 {
      from {
        fill: #A093ED;
      }
      to {
        fill: #1AEFBC;
      }
    }
  }

  .st33{
    animation: step33 576ms ease;
    animation-fill-mode: forwards;
    animation-delay: 6s;

    @keyframes step33 {
      from {
        fill: #A093ED;
      }
      to {
        fill: #1AEFBC;
      }
    }
  }

  ${createAnimationCSS()};
`

const HeroManImage = styled(ManImage)`
  height: 8rem;
  width: 9rem;
  position: absolute;
  top: 27.4rem;
  left: 131rem;

  @media screen and (max-width: 768px) {
    height: 2.1rem;
    width: 2.5rem;
    position: absolute;
    top: 8.2rem;
    left: 32.9rem;
  }

  @media screen and (max-width: 460px) {
    //display: none;
    height: 4.1rem;
    width: 4.5rem;
    position: absolute;
    top: 14.7rem;
    left: 35.4rem;
  }

  path {
    animation: step-man 576ms ease;
    animation-fill-mode: forwards;
    animation-delay: 7s;

    @keyframes step-man {
      from {
        fill: #A093ED;
      }
      to {
        fill: #1AEFBC;
      }
    }
  }
`

const HeroContent = styled.div`
  max-width: 56.5rem;
  margin-left: 26rem;
  //margin-top: 15rem;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 95px 17px 20px;
  }
`

const HeroTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  font-style: italic;
  font-weight: 800;
  text-transform: capitalize;
  color: #1AEFBC;
  font-size: 8.4rem;
  line-height: 103.19%;

  @media screen and (max-width: 768px) {
    font-size: 50px;
    width: 185px;
    word-break: break-word;
    text-transform: capitalize;
  }
`

const HeroSteps = styled.div`
  display: flex;
  margin-top: 5rem;
  margin-bottom: 8.3rem;
  width: 48rem;

  @media screen and (max-width: 768px) {
    width: auto;
    flex-wrap: wrap;
    margin-top: 150px;
    margin-bottom: 52px;
  }
`

const HeroStep = styled.div`
  color: #A093ED;
  text-transform: uppercase;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.6rem;
  background: url(${HeroStepImage}) no-repeat 1.6rem 0;
  background-size: 100%;
  animation: change 575ms ease;
  animation-fill-mode: forwards;

  @keyframes change {
    from {
      color: #A093ED;
      background: url(${HeroStepImage}) no-repeat 1.6rem 0;
      background-size: 100%;
    }
    to {
      color: #1AEFBC;
      background: url(${HeroStepImageFilled}) no-repeat 1.6rem 0;
      background-size: 100%;
    }
  }

  &:nth-child(1) {
    animation-delay: 2s;
  }
  &:nth-child(2) {
    animation-delay: 4s;
  }
  &:nth-child(3) {
    animation-delay: 6s;
  }

  @media screen and (max-width: 768px) {
    padding-top: 25px;
    flex-basis: 30%;
  }
`
const HeroStepNumber = styled.div`
  font-family: 'SF Pro Display Thin Italic', sans-serif;
  font-weight: 200;
  font-size: 3.6rem;
  line-height: 92%;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`

const HeroStepTitle = styled.div`
  font-family: 'SF Pro Display Semibold Italic', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  word-spacing: 100vw;
  margin-left: 1.1rem;
  line-height: 100%;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: 9px;
  }
`
