import React from 'react';
import Hero from "./sections/Hero";
import ToReachTheGoal from "./sections/ToReachTheGoal";
import StartTheWay from "./sections/StartTheWay";
import Benefits from "./sections/Benefits";
// import Team from "./sections/Team";
import FAQ from "./sections/FAQ";
import RoadMap from "./sections/RoadMap";
import Join from "./sections/Join";
import RichText from "./sections/RichText";

const sectionComponents = {
  "sections.hero": Hero,
  "sections.to-reach-the-goal": ToReachTheGoal,
  "sections.start-the-way": StartTheWay,
  "sections.benefits": Benefits,
  // "sections.team": Team,
  "sections.faq": FAQ,
  "sections.road-map": RoadMap,
  "sections.join": Join,
  "sections.rich-text": RichText
}

// Display a section individually
const Section = ({ sectionData }) => {

  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.strapi_component || sectionData.__component]

  if (!SectionComponent) {
    // No matching component for this page section
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} />
}

// Display the list of sections
const Sections = ({sections}) => {
  return (
    <div>
      {sections.map((section, i) => (
        <Section
          sectionData={section}
          key={`${section.strapi_component}${section.id}${i}`}
        />
      ))}
    </div>
  )
};

export default Sections
