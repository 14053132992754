import React from 'react';
import styled from "styled-components";
import CheckImage from '../../images/check.svg'
import {motion} from "framer-motion";
import {fromBottom, fromLeft} from "../../utils/animationVariants";
import Mobile from "../mobile";

const ToReachTheGoal = ({data}) => {
  return (
    <GoalContainer id='to-reach-the-goal'>
      <GoalContent>
        <GoalTitle
          as={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromLeft}
        >{data.title}</GoalTitle>
        {data.steps.map((item, index) => (
          <GoalStep
            key={index}
            as={motion.div}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={fromLeft}
          >
            <GoalStepImage src={CheckImage} alt='check'/>
            {item.title}
          </GoalStep>
        ))}
      </GoalContent>
      <MobileWrap
          as={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromBottom}
      >
        <Mobile/>
      </MobileWrap>
    </GoalContainer>
  )
};

export default ToReachTheGoal

const GoalContainer = styled.div`
  min-height: 56vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 12rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    min-height: auto;
    padding: 90px 18px 70px;
  }
`

const GoalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 45rem;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    order: 2;
    width: 100%;
    margin: 0;
    padding-top: 57px;
  }
`
const GoalTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  color: #1AEFBC;
  font-style: italic;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 103.19%;
  margin-bottom: 7.4rem;

  @media screen and (max-width: 768px) {
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 40px;
  }
`
const GoalStep = styled.div`
  font-family: 'SF Pro Display Semibold Italic', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 2.2rem;
  width: 37rem;
  line-height: 135.2%;
  color: #FFFFFF;
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 135.2%;
    width: 100%;
    margin-bottom: 20px;
  }
`
const GoalStepImage = styled.img`
  margin-right: 3.7rem;
  width: 3.1rem;
  height: 3.1rem;

  @media screen and (max-width: 768px) {
    margin-right: 29px;
    width: 24px;
    height: 24px;
  }
`

const MobileWrap = styled.div`
  display: block;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    margin: 10px 5% 10px 0;
  }
`
