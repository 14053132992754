import React from 'react';
import styled from "styled-components";
import StepsImage from '../../images/step-line-with-icons.inline.svg'
import LeadForm from "../lead-form";
import StepIcon from '../../images/steps-icon.svg'
import {motion} from "framer-motion";
import {fromLeft} from "../../utils/animationVariants";

const variants = {
  visible: i => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
      duration: 1,
    },
  }),
  hidden: { opacity: 0 },
}

const StartTheWay = ({data}) => {
  return (
    <WayContainer id='start-the-way'>
      <WayContent>
        <WayTitle
          as={motion.div}
          initial={"offscreen"}
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={fromLeft}
        >{data.title}</WayTitle>
        <LeadForm/>
      </WayContent>
      <WaySteps>
        {data.steps.map((step, i) => (
          <WayStep
            key={i}
            as={motion.div}
            custom={i}
            initial={"hidden"}
            whileInView="visible"
            viewport={{ once: true, amount: 0.8 }}
            variants={variants}
          >
            <WayStepImage src={step.image.localFile.url} alt={step.title} width='100' height='100'/>
            <WayStepIcon src={StepIcon} alt='circle'/>
            <WayStepTextWrap>
              <WayStepTitle>
                <WayStepTitleNumber>{(i + 1).toString().padStart(2, "0")}</WayStepTitleNumber>
                {step.title}
              </WayStepTitle>
              <WayStepDescription>{step.description}</WayStepDescription>
            </WayStepTextWrap>
          </WayStep>
        ))}
        <StepsLineImage/>
      </WaySteps>

    </WayContainer>
  )
};

export default StartTheWay

const WayContainer = styled.div`
  min-height: 56vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 12rem;
  background: #201860;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    min-height: auto;
    padding: 70px 18px;
  }
`

const WayContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;
  margin: 0 auto;
  padding-top: 9rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding-top: 57px;
  }
`
const WayTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  color: #1AEFBC;
  font-style: italic;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 103.19%;
  margin-bottom: 7.4rem;

  @media screen and (max-width: 768px) {
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 40px;
  }
`

const WaySteps = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  width: 60rem;
  height: 60rem;
  margin-left: 10rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-left: 0;
  }
`

const WayStepImage = styled.img`
  height: 6.5rem;
  width: auto;
  margin-left: -1rem;


  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: 100px;
    max-width: 100px;
    height: auto;
    max-height: 46px;
    margin: 0;
    transform: translate(0, -30%);
  }
`
const WayStepIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  margin-top: 1rem;
  display: block;
  opacity: 0;

  @media screen and (max-width: 768px) {
    opacity: 1;
    width: 27px;
    height: 27px;
    margin-top: 0;
    position: absolute;
    left: 106px;
    top: -2px;
  }
`

const WayStepTextWrap = styled.div`
  @media screen and (max-width: 768px) {
    border-left: 13px solid #A093ED;
    margin-left: 13px;
    padding: 0 20px 35px;
  }

`
const WayStepTitle = styled.div`
  font-family: 'SF Pro Display Semibold Italic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 145.2%;
  text-transform: uppercase;
  color: #1AEFBC;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`
const WayStepTitleNumber = styled.span`
  font-family: 'SF Pro Display Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 145.2%;
  text-transform: uppercase;
  color: #1AEFBC;
  margin-right: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 21px;
    margin-right: 5px;
  }
`
const WayStepDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 132.2%;
  color: #FFFFFF;
  max-width: 23rem;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    max-width: 100%;
    margin-top: 13px;
  }
`
const WayStep = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 1.1rem;
  position: relative;
  z-index: 1;
  max-width: 25rem;

  &:nth-child(3), &:nth-child(4) {
    ${WayStepImage} {
      height: 7.7rem;
      margin-left: -3rem;

      @media screen and (max-width: 768px) {
        height: auto;
        margin: 0;
      }
    }
  }

  &:nth-child(5) {
    max-width: 40rem;
    ${WayStepImage} {
      height: 7.9rem;
      margin-left: -3rem;
    }
    ${WayStepDescription} {
      display: table-row;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
      ${WayStepImage} {
        width: 100px;
        min-width: 100px;
        max-height: 100%;
        height: auto;
        margin-left: 0;
      }
      ${WayStepDescription} {
        display: block;
      }
      ${WayStepTextWrap} {
        padding-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: row;
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  @media screen and (min-width: 769px) {
    &:nth-child(1) { order: 1; }
    &:nth-child(2) { order: 2; }
    &:nth-child(3) { order: 4; }
    &:nth-child(4) { order: 3; }
    &:nth-child(5) { order: 5; }
  }
`

const StepsLineImage = styled(StepsImage)`
  height: 50rem;
  width: 68rem;
  position: absolute;
  top: 8.4rem;
  left: -12rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
