export const fromTop = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: [0, 1],
    y: [-100, 0],
    transition: {
      type: "spring",
      times: [0, 0.8],
      duration: 0.8,
    }
  }
};

export const fromLeft = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: [0, 1],
    x: [-150, 0],
    transition: {
      type: "spring",
      times: [0, 0.8],
      duration: 0.8,
    }
  }
};

export const fromRight= {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: [0, 1],
    x: [150, 0],
    transition: {
      type: "spring",
      times: [0, 0.8],
      duration: 0.8,
    }
  }
};

export const fromBottom= {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: [0, 1],
    y: [100, 0],
    transition: {
      type: "spring",
      times: [0, 0.8],
      duration: 0.8,
    }
  }
};
