import React from 'react';
import styled from "styled-components";
import {motion} from "framer-motion";
import {fromLeft, fromBottom} from "../../utils/animationVariants";

const RoadMonth = ({month, bottom}) => {
  return (
    <RoadMonthContainer bottom={bottom}>
      <RoadMonthTitle>
        {month.title}
      </RoadMonthTitle>
      <RoadMonthList bottom={bottom}>
        {month.steps.map((step, i) => (
          <li key={i}>
            {step.title}
          </li>
        ))}
      </RoadMonthList>
    </RoadMonthContainer>
  )
};

const RoadMap = ({data}) => {
  return (
    <RoadContainer id='road-map'>
      <RoadTitle
        as={motion.div}
        initial={"offscreen"}
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={fromLeft}
      >{data.title}</RoadTitle>
      <RoadMapMonths
        as={motion.div}
        initial={"offscreen"}
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={fromBottom}
      >
        <FirstLine/>
        {data.months.map((month, i) => (
          <RoadMonth month={month} key={i} bottom={i%2 === 0}/>
        ))}
        <LastLine/>
      </RoadMapMonths>
    </RoadContainer>
  )
};

export default RoadMap

const RoadContainer = styled.div`
  min-height: 36vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #201860;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    min-height: auto;
    overflow: scroll;
    overflow-y: hidden;
  }
`
const RoadTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  font-style: italic;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 103.19%;
  text-align: center;
  color: #1AEFBC;
  margin-top: 21.3rem;
  margin-bottom: 35rem;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    margin-bottom: 0;
    margin-top: 90px;
  }
`

const FirstLine = styled.div`
  border-top: 0.6rem solid #1AEFBC;
  width: 100%;

  @media screen and (max-width: 768px) {
    border-width: 4.5px;
    min-width: 50px;
  }
`
const LastLine = styled.div`
  border-top: 0.6rem solid #76569E;
  width: 100%;

  @media screen and (max-width: 768px) {
    border-width: 4.5px;
    min-width: 50px;
  }
`

const RoadMonthContainer = styled.div`
  padding-top: 2.3rem;
  border-top: 0.6rem solid #1AEFBC;
  position: relative;
  width: 18rem;
  min-width: 18rem;
  display: flex;
  justify-content: center;

  &:after {
    position: absolute;
    left: 50%;
    right: 0;
    top: -0.6rem;
    border-top: 0.6rem solid #1AEFBC;
    content: '';
  }

  @media screen and (max-width: 768px) {
    padding-top: 12px;
    border-top: 4.5px solid #1AEFBC;
    width: 140px;
    min-width: 140px;

    &:after {
      position: absolute;
      left: 50%;
      right: 0;
      top: -4.5px;
      border-top: 4.5px solid #1AEFBC;
      content: '';
    }
  }
`
const RoadMonthTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 145.2%;
  text-align: center;
  color: #1AEFBC;
  position: relative;

  &:after {
    content: '';
    width: 2.8rem;
    height: 2.8rem;
    background: #1AEFBC;
    border-radius: 1.4rem;
    position: absolute;
    top: -4rem;
    z-index: 2;
    left: calc(50% - 1.4rem);
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;

    &:after {
      width: 22px;
      height: 22px;
      border-radius: 11px;
      top: -25px;
      left: calc(50% - 11px);
    }
  }

`
const RoadMonthList = styled.ul`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 145.2%;
  color: #312E62;
  padding: 4rem 5rem 3rem;
  background: #1AEFBC;
  border-radius: 2.4rem;
  position: absolute;
  width: max-content;
  height: max-content;
  bottom: 230%;

  li {
    font-family: 'SF Pro Display Medium', sans-serif;
    width: fit-content;
  }

  &:after{
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: -7rem;
    height: 7rem;
    width: 4rem;
    left: 50%;
    border-top-left-radius: 3rem;
    box-shadow: 0 -3rem 0 0;
    color: #1aefbc
  }
  &:before{
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: -7rem;
    height: 7rem;
    width: 4rem;
    right: 50%;
    border-top-right-radius: 3rem;
    box-shadow: 0 -3rem 0 0;
    color: #1aefbc;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 30px 25px 20px 35px;
    border-radius: 19px;

    &:after{
      content: "";
      position: absolute;
      background-color: transparent;
      bottom: -50px;
      height: 50px;
      width: 42px;
      left: 50%;
      border-top-left-radius: 20px;
      box-shadow: 0 -23px 0 0;
      color: #1aefbc
    }
    &:before{
      content: "";
      position: absolute;
      background-color: transparent;
      bottom: -50px;
      height: 50px;
      width: 42px;
      right: 50%;
      border-top-right-radius: 20px;
      box-shadow: 0 -23px 0 0;
      color: #1aefbc;
    }
  }
`
const RoadMapMonths = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24rem;

  @media screen and (max-width: 768px) {
    margin: 250px 0;
  }

  ${RoadMonthContainer} {
    &:nth-child(4){
      &:after {
        border-color: #76569E;
      }
    }

    &:nth-child(n+5){
      border-color: #76569E;

      &:after {
        border-color: #76569E;
      }

      ${RoadMonthTitle} {
        color: #ADAAC4;

        &:after {
          background: #76569E;
        }
      }

      ${RoadMonthList} {
        color: #FFFFFF;
      }
    }

    &:nth-child(2n+1){
      ${RoadMonthList} {
        position: absolute;
        top: 170%;

        &:after{
          content: "";
          position: absolute;
          background-color: transparent;
          top: -8.5rem;
          height: 8.5rem;
          width: 4rem;
          left: 50%;
          border-bottom-left-radius: 3rem;
          box-shadow: 0 3rem 0 0;
        }
        &:before{
          content: "";
          position: absolute;
          background-color: transparent;
          top: -8.5rem;
          height: 8.5rem;
          width: 4rem;
          right: 50%;
          border-bottom-right-radius: 3rem;
          box-shadow: 0 3rem 0 0;
        }

        @media screen and (max-width: 768px) {
          &:after{
            content: "";
            position: absolute;
            background-color: transparent;
            top: -65px;
            height: 65px;
            width: 42px;
            left: 50%;
            border-bottom-left-radius: 20px;
            box-shadow: 0 23px 0 0;
          }
          &:before{
            content: "";
            position: absolute;
            background-color: transparent;
            top: -65px;
            height: 65px;
            width: 42px;
            right: 50%;
            border-bottom-right-radius: 20px;
            box-shadow: 0 23px 0 0;
          }
        }
      }
    }

    &:nth-child(5){
      ${RoadMonthList} {
        background: #A4429A;

        &:after, &:before {
          color: #A4429A;
          border-bottom-color: #A4429A;
        }
      }
    }

    &:nth-child(6){
      ${RoadMonthList} {
        background: #72318D;

        &:after, &:before  {
          color: #72318D;
          border-top-color: #72318D;
        }
      }
    }

    &:nth-child(7){
      ${RoadMonthList} {
        background: #52308E;

        &:after, &:before  {
          color: #52308E;
          border-bottom-color: #52308E;
        }
      }
    }
  }
`
