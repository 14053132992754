import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useStaticQuery, graphql} from "gatsby";
import {useForm} from "react-hook-form";
import StarsImage from "../images/stars.svg"
import CustomLink from "./elements/custom-link";
import CursorHand from "../images/cursor-hand.gif"
import axios from "axios";
import PopupSuccessSubmit from "./modals/PopupSuccessSubmit";
import {useContext} from "react";
import {LaunchContext} from "./context";
import {motion} from "framer-motion";
import {fromBottom} from "../utils/animationVariants";


const LeadForm = ({minimize = false, closePopup = () => {}}) => {
  const data = useStaticQuery(graphql`
    query {
      strapiLeadForm {
        appText
        emailPlaceholder
        locale
        title
        subTitle
        joinedPeople
        appLinks {
          newTab
          url
          image {
            localFile {
              url
            }
          }
        }
        submitButton {
          type
          text
        }
      }
    }
  `)

  const {disableLaunchModal} = useContext(LaunchContext)

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm();

  const [submitMessage, setSubmitMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if(!isPopupOpen) closePopup(false)
  }, [isPopupOpen, closePopup]);

  const onSubmit = (data) => {
    setSubmitMessage("");
    const url = process.env.GATSBY_API_URL;

    axios.defaults.withCredentials = true;

    axios.get(`${url}/csrf`).then(() => {
      axios.post(`${url}/waiting-list`, {...data})
        .then(function (response) {
          if (!response.data.success) {
            return setSubmitMessage(response.data.message);
          }
          reset();
          disableLaunchModal();
          setIsPopupOpen(true);
        })
        .catch(function (error) {
          setSubmitMessage(error.response?.data.message || error.message);
        });
    });
  };

  return (
    <div>
      <LeadFormContainer
        className={minimize ? 'minimize' : ''}
        as={motion.div}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={fromBottom}
      >
        <LeadFormTitle>{data.strapiLeadForm.title}</LeadFormTitle>
        <LeadFormSubTitle>{data.strapiLeadForm.subTitle}</LeadFormSubTitle>
        <Form onSubmit={handleSubmit(onSubmit)} className={errors.email ? 'error' : ''}>
          <EmailInput {...register('email', {required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/})}
                      placeholder={data.strapiLeadForm.emailPlaceholder} type="email"/>
          <SubmitButton type="submit" value={data.strapiLeadForm.submitButton.text}/>
          <ErrorMessage>
            {errors.email && "Not correct e-mail."} {submitMessage}
          </ErrorMessage>
        </Form>

        <StarsWrap>
          <Stars src={StarsImage} alt='stars'></Stars>
          {data.strapiLeadForm.joinedPeople}
        </StarsWrap>

        <AppWrap>
          {data.strapiLeadForm.appText}
          {data.strapiLeadForm.appLinks.map((item, index) => (
            <AppLink link={item} key={index}>
              <AppLinkImage src={item.image.localFile.url} alt='app' aria-label='app link' width='50' height='50'/>
            </AppLink>
          ))}
        </AppWrap>
        {isPopupOpen && <PopupSuccessSubmit setShow={setIsPopupOpen}/>}
      </LeadFormContainer>
    </div>
  );
};

export default LeadForm;

const LeadFormTitle = styled.div`
  font-family: 'SF Pro Display Semibold Italic', serif;
  font-style: italic;
  font-weight: 600;
  font-size: 2rem;
  color: #FFFFFF;
  text-transform: uppercase;
  line-height: 131%;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`
const LeadFormSubTitle = styled.div`
  font-family: 'SF Pro Display Semibold Italic', serif;
  font-style: italic;
  font-weight: 600;
  font-size: 2rem;
  color: #1AEFBC;
  text-transform: uppercase;
  line-height: 131%;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`

const Form = styled.form`
  background: #464377;
  border-radius: 1.4rem;
  width: 100%;
  max-width: 47rem;
  height: 6rem;
  display: flex;
  border: 0.1rem solid #7B77B9;
  justify-content: space-between;
  padding: 0.2rem;
  margin-top: 3.1rem;
  position: relative;

  &.error {
    border-color: #F56356;
  }

  @media screen and (max-width: 767px) {
    border-radius: 11px;
    height: 47px;
    border: 1px solid #7B77B9;
    padding: 2px;
    margin-top: 25px;
    max-width: 100%;
  }
`
const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 122.69%;
  color: #FF3A44;
  margin: 0;
  position: absolute;
  left: 4%;
  top: 6.3rem;

  @media screen and (max-width: 767px) {
    top: 50px;
    font-size: 13px;
    left: 18px;
  }
`

const EmailInput = styled.input`
  font-weight: 400;
  font-size: 1.7rem;
  color: #A093ED;
  padding-left: 4%;
  background: none;
  border: none;
  width: 55%;
  letter-spacing: 0.04em;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #A093ED;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    padding-left: 18px;
  }
`
const SubmitButton = styled.input`
  font-family: 'SF Pro Display Bold', sans-serif;
  display: block;
  -webkit-appearance: none;
  height: 100%;
  background: #F56356;
  border: none;
  border-radius: 1.2rem;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: url(${CursorHand}), auto;
  padding: 0 2.5rem;

  &:hover {
    background: #F25446;
  }

  &:active {
    background: #E44638;
  }

  @media screen and (max-width: 767px) {
    width: 44%;
    font-size: 12px;
    border-radius: 10px;
  }
`

const StarsWrap = styled.div`
  margin-top: 2.5rem;
  font-weight: 400;
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-top: 26px;
    font-size: 13px;
  }
`
const Stars = styled.img`
  margin-right: 0.9rem;
  width: 7rem;

  @media screen and (max-width: 767px) {
    margin-right: 9px;
    width: 83px;
  }
`

const AppWrap = styled.div`
  font-family: 'SF Pro Display Semibold Italic', sans-serif;
  display: flex;
  align-items: center;
  color: rgba(160, 147, 237, 0.61);
  font-style: italic;
  font-weight: 600;
  font-size: 2.2rem;
  margin-top: 4.8rem;
  pointer-events: none;

  @media screen and (max-width: 767px) {
    font-size: 18px;
    margin-top: 37px;
  }
`

const AppLink = styled(CustomLink)`
  margin-left: 2.4rem;
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 767px) {
    margin-left: 24px;
  }
`

const AppLinkImage = styled.img`
  width: 3.2rem;

  @media screen and (max-width: 767px) {
    width: 26px;
  }
`

const LeadFormContainer = styled.div`
  &.minimize {
    ${LeadFormSubTitle}, ${LeadFormTitle}, ${StarsWrap}, ${AppWrap} {
      display: none;
    }

    ${Form} {
      max-width: 100%;
      border: none;
      background: none;
      flex-direction: column;
      height: auto;

      ${ErrorMessage} {
        @media screen and (max-width: 1300px) {
          top: 50px;
          font-size: 9px;
        }
        @media screen and (max-width: 767px) {
          top: 60px;
          font-size: 13px;
        }
      }

      @media screen and (max-width: 1300px) {
        margin-top: 20px;
      }

      @media screen and (max-width: 767px) {
        padding-right: 0;
      }

      ${EmailInput} {
        background: #464377;
        border-radius: 1.4rem;
        width: 100%;
        height: 6rem;
        min-height: 6rem;
        display: flex;
        border: 0.1rem solid #7B77B9;

        @media screen and (max-width: 1300px) {
          min-height: 40px;
          height: 40px;
          border: 1px solid #7B77B9;
          border-radius: 9px;
          font-size: 16px;
        }

        @media screen and (max-width: 767px) {
          min-height: 46px;
          height: 46px;
          font-size: 16px;
        }
      }

      ${SubmitButton} {
        width: 100%;
        min-height: 6rem;
        margin-top: 2.2rem;

        @media screen and (max-width: 1300px) {
          min-height: 40px;
          height: 40px;
          margin-top: 15px;
          font-size: 12px;
          border: 1px solid #7B77B9;
          border-radius: 9px;
        }

        @media screen and (max-width: 767px) {
          min-height: 46px;
          height: 46px;
          margin-top: 20px;
        }
      }
    }
  }

`
