import React from 'react';
import styled from "styled-components";
import LeadForm from "../lead-form";
import GiftBG from "../../images/gift-green.svg"
import Modal from "../elements/modal";

const PopupLaunch = ({data, setIsOpen}) => {
  return (
    <>
      <Modal setShow={setIsOpen}>
        <GiftWrap
          dangerouslySetInnerHTML={{
            __html: data.giftText.data.giftText,
          }}
        />
        <LaunchContent>
          <LaunchTitle>{data.title}</LaunchTitle>
          <LaunchSubTitle>{data.subTitle}</LaunchSubTitle>
          <LeadForm minimize={true} closePopup={setIsOpen}/>
        </LaunchContent>
      </Modal>
    </>
  );
};

export default PopupLaunch;

const GiftWrap = styled.div`
  width: 50rem;
  margin-left: 5rem;
  background: url(${GiftBG}) no-repeat center center;
  background-size: contain;
  height: 45rem;
  color: #fff;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 122.69%;
  text-align: center;
  padding: 26.5rem 10rem 0 0;

  p{
    &:first-child {
      font-family: 'SF Pro Display Heavy Italic', sans-serif;
      span {
        font-family: 'SF Pro Display Heavy Italic', sans-serif;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    width: 365px;
    margin-left: 32px;
    font-weight: 400;
    font-size: 14px;
    padding: 254px 62px 0 0;
    height: 444px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 12px;
    padding: 155px 75px 0 0;
    height: 255px;
    margin: 72px 0 24px;
  }
`

const LaunchContent = styled.div`
  @media screen and (max-width: 1300px) {
    width: 230px;
  }
  @media screen and (max-width: 767px) {
    margin: 0 36px;
    width: auto;
  }
`

const LaunchTitle = styled.div`
  font-family: 'SF Pro Display Heavy Italic', sans-serif;
  color: #1AEFBC;
  font-style: italic;
  font-weight: 800;
  font-size: 3.9rem;
  line-height: 106.69%;
  margin-bottom: 1rem;
  max-width: 31rem;

  @media screen and (max-width: 1300px) {
    font-size: 26px;
    margin-bottom: 10px;
    max-width: 210px;
  }

  @media screen and (max-width: 767px) {
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 10px;
    max-width: 280px;
  }
`
const LaunchSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 122.69%;
  color: #1AEFBC;
  margin-bottom: 3rem;
  max-width: 29rem;

  @media screen and (max-width: 1300px) {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 180px;
  }

  @media screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 122.69%;
    margin-bottom: 30px;
    max-width: 200px;
  }
`
